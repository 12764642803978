import React, { useState, useEffect, useRef } from 'react';
import EmojiPicker from 'emoji-picker-react';
import axios from 'axios';
import './Chat.css';
import { FaRegSmile, FaImage, FaGift, FaPaperPlane } from 'react-icons/fa';

const Chat = ({ walletAddress }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showGiphyPicker, setShowGiphyPicker] = useState(false);
  const [giphyResults, setGiphyResults] = useState([]);
  const chatRef = useRef(null);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getNickname = (address) => {
    return address.slice(-5);
  };

  const handleSendMessage = () => {
    if (inputMessage.trim() !== '') {
      const newMessage = {
        sender: walletAddress,
        content: inputMessage,
        timestamp: new Date().toISOString(),
      };
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setInputMessage('');
    }
  };

  const handleEmojiClick = (emojiObject) => {
    setInputMessage(prevInput => prevInput + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const handleGiphySearch = async (query) => {
    if (query.trim() === '') {
      setGiphyResults([]);
      return;
    }
    try {
      const response = await axios.get(`https://api.giphy.com/v1/gifs/search`, {
        params: {
          api_key: 'YOUR_GIPHY_API_KEY', // Замените на ваш реальный ключ API Giphy
          q: query,
          limit: 10
        }
      });
      setGiphyResults(response.data.data);
    } catch (error) {
      console.error('Error fetching GIFs:', error);
    }
  };

  const handleGiphySelect = (gifUrl) => {
    const newMessage = {
      sender: walletAddress,
      content: gifUrl,
      type: 'gif',
      timestamp: new Date().toISOString(),
    };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setShowGiphyPicker(false);
    setGiphyResults([]);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newMessage = {
          sender: walletAddress,
          content: reader.result,
          type: 'image',
          timestamp: new Date().toISOString(),
        };
        setMessages(prevMessages => [...prevMessages, newMessage]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const triggerImageUpload = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="chat-container">
      <div className="chat-messages" ref={chatRef}>
        {messages.map((msg, index) => (
          <div key={index} className="message">
            <span className="message-nickname">{getNickname(msg.sender)}:</span>
            {msg.type === 'image' || msg.type === 'gif' ? (
              <img src={msg.content} alt="Shared content" className="chat-image" />
            ) : (
              <span className="message-content">{msg.content}</span>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type a message..."
        />
        <button className="chat-button" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
          <FaRegSmile />
        </button>
        <button className="chat-button" onClick={() => setShowGiphyPicker(!showGiphyPicker)}>
          <FaGift />
        </button>
        <button className="chat-button" onClick={triggerImageUpload}>
          <FaImage />
        </button>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />
        <button className="chat-button chat-send-button" onClick={handleSendMessage}>
          <FaPaperPlane />
        </button>
      </div>
      {showEmojiPicker && (
        <div className="emoji-picker-container">
          <EmojiPicker onEmojiClick={handleEmojiClick} />
        </div>
      )}
      {showGiphyPicker && (
        <div className="giphy-picker-container">
          <input
            type="text"
            placeholder="Search GIFs..."
            onChange={(e) => handleGiphySearch(e.target.value)}
          />
          <div className="giphy-results">
            {giphyResults.map((gif) => (
              <img
                key={gif.id}
                src={gif.images.fixed_height_small.url}
                alt={gif.title}
                onClick={() => handleGiphySelect(gif.images.original.url)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;