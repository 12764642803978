import React, { useState, useEffect } from 'react';

const FuturisticRoadmapWithBeetrade = () => {
  const [activePhase, setActivePhase] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const roadmapData = [
    {
      phase: 1,
      title: "Genesis",
      description: "Laying the groundwork for a revolutionary platform",
      items: [
        "Initiate closed alpha testing",
        "Deploy Solana testnet",
        "Conduct comprehensive security audits",
        "Integrate popular crypto wallets",
        "Launch Bee token with advanced tokenomics"
      ]
    },
    {
      phase: 2,
      title: "Ascension",
      description: "Expanding our reach and enhancing user experience",
      items: [
        "Transition to Solana mainnet",
        "Release open beta to the public",
        "Execute strategic marketing campaigns",
        "Implement instant token purchase feature",
        "Develop advanced trading bot",
        "Introduce daily token burning mechanism"
      ]
    },
    {
      phase: 3,
      title: "Expansion",
      description: "Breaking barriers and reaching new horizons",
      items: [
        "Expand to multiple blockchain networks",
        "Launch global outreach program",
        "Secure listings on major CEXs"
      ]
    },
    {
      phase: 4,
      title: "Revolution",
      description: "Redefining the crypto launch paradigm",
      items: [
        "Implement AI-driven scam protection insurance",
        "Enhance Bee token utility across DeFi ecosystem",
        "Introduce revolutionary tokenomics model"
      ]
    }
  ];

  const comparisonData = [
    { feature: "Advanced security with fund locking", beetrade: true, pumpfun: false },
    { feature: "Customizable token parameters", beetrade: true, pumpfun: false },
    { feature: "Timed launches for maximum impact", beetrade: true, pumpfun: false },
    { feature: "Rich communication features", beetrade: true, pumpfun: false },
    { feature: "Pre-launch marketing tools", beetrade: true, pumpfun: false },
    { feature: "Simple meme token creation", beetrade: true, pumpfun: true },
    { feature: "Bonding curve liquidity model", beetrade: true, pumpfun: true },
    { feature: "Low issuance fees", beetrade: true, pumpfun: true },
    { feature: "Instant tradability", beetrade: true, pumpfun: true },
    { feature: "No presales or team allocations", beetrade: true, pumpfun: true }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      
      const newActivePhase = Math.min(
        Math.floor((position / (document.documentElement.scrollHeight - window.innerHeight)) * (roadmapData.length + 1)),
        roadmapData.length
      );
      setActivePhase(newActivePhase);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="futuristic-vertical-roadmap">
      <div className="cosmic-background"></div>
      <div className="holographic-overlay"></div>
      <div className="roadmap-header">
        <h1>BEETOP Evolution</h1>
        <p>Charting the Course to Crypto Dominance</p>
      </div>
      <div className="roadmap-timeline">
        {roadmapData.map((phase, index) => (
          <div
            key={phase.phase}
            className={`roadmap-phase ${activePhase >= index ? 'active' : ''} ${activePhase === index ? 'current' : ''}`}
          >
            <div className="phase-connector">
              <div className="connector-line"></div>
              <div className="connector-dot"></div>
            </div>
            <div className="phase-content">
              <div className="phase-header">
                <span className="phase-number">{phase.phase}</span>
                <h2>{phase.title}</h2>
              </div>
              <p className="phase-description">{phase.description}</p>
              <ul className="phase-items">
                {phase.items.map((item, itemIndex) => (
                  <li key={itemIndex}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
        
        {/* Bee.trade section */}
        <div className={`roadmap-phase beetrade-phase ${activePhase >= roadmapData.length ? 'active current' : ''}`}>
          <div className="phase-connector">
            <div className="connector-line"></div>
            <div className="connector-dot"></div>
          </div>
          <div className="phase-content beetrade-content">
            <div className="phase-header">
              <span className="phase-number">∞</span>
              <h2>The Future: Bee.trade</h2>
            </div>
            <p className="phase-description">Revolutionizing the Crypto Launch Paradigm</p>
            <div className="beetrade-features">
              <div className="feature">
                <h3>Creator's Wallet Lock</h3>
                <p>Ensuring user security and trust by preventing fraud and "rug pulls"</p>
              </div>
              <div className="feature">
                <h3>Customizable Tokenomics</h3>
                <p>Flexible settings for token creation, adapting to individual needs and strategies</p>
              </div>
              <div className="feature">
                <h3>Timed Project Launch</h3>
                <p>Set specific dates for launches, building anticipation and maximizing participation</p>
              </div>
              <div className="feature">
                <h3>Enhanced Communication</h3>
                <p>User-friendly tools fostering active community engagement and growth</p>
              </div>
              <div className="feature">
                <h3>Pre-Launch Marketing</h3>
                <p>Advertising opportunities to promote projects even before their official launch</p>
              </div>
            </div>
            <div className="platform-comparison">
              <h3>Bee.trade vs Pump.fun</h3>
              <div className="comparison-container">
                <div className="comparison-table">
                  <div className="comparison-header">
                    <div>Feature</div>
                    <div>Bee.trade</div>
                    <div>Pump.fun</div>
                  </div>
                  {comparisonData.map((item, index) => (
                    <div key={index} className="comparison-row">
                      <div>{item.feature}</div>
                      <div>
                        {item.beetrade ? <span className="checkmark beetrade-checkmark">✓</span> : ''}
                      </div>
                      <div>
                        {item.pumpfun ? <span className="checkmark pumpfun-checkmark">✓</span> : ''}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = `
  @keyframes cosmic-flow {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }

  @keyframes holographic-flicker {
    0% { opacity: 0.3; }
    50% { opacity: 0.5; }
    100% { opacity: 0.3; }
  }

  @keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
  }

  .futuristic-vertical-roadmap {
    min-height: 100vh;
    padding: 50px 20px;
    color: #fff;
    font-family: 'Arial', sans-serif;
    position: relative;
    overflow-x: hidden;
  }

  .cosmic-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, #0a0a0a, #1a1a1a, #2a2a2a);
    background-size: 400% 400%;
    animation: cosmic-flow 15s ease infinite;
    z-index: -2;
  }

  .holographic-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 20% 50%, rgba(0, 245, 212, 0.1) 0%, transparent 50%),
      radial-gradient(circle at 80% 50%, rgba(123, 44, 191, 0.1) 0%, transparent 50%);
    z-index: -1;
    animation: holographic-flicker 5s infinite alternate;
  }

  .roadmap-header {
    text-align: center;
    margin-bottom: 50px;
    position: relative;
    z-index: 1;
  }

  .roadmap-header h1 {
    font-size: 4rem;
    font-weight: bold;
    background: linear-gradient(45deg, #00f5d4, #7b2cbf);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
    text-shadow: 0 0 10px rgba(0, 245, 212, 0.5);
  }

  .roadmap-header p {
    font-size: 1.5rem;
    color: #00f5d4;
    opacity: 0.8;
  }

  .roadmap-timeline {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    padding-top: 50px;
  }

  .roadmap-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 100%;
    background: linear-gradient(to bottom, #00f5d4, #7b2cbf);
    opacity: 0.3;
  }

  .roadmap-phase {
    position: relative;
    margin-bottom: 100px;
    transition: all 0.5s ease;
  }

  .roadmap-phase.active {
    opacity: 1;
  }

  .roadmap-phase.current {
    animation: float 3s ease-in-out infinite;
  }

  .phase-connector {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .connector-line {
    width: 100px;
    height: 4px;
    background: linear-gradient(to right, #00f5d4, #7b2cbf);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .connector-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #00f5d4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 20px #00f5d4;
  }

  .phase-content {
    width: calc(50% - 50px);
    padding: 30px;
    background: rgba(26, 26, 26, 0.8);
    border-radius: 15px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    opacity: 0.7;
  }

  .roadmap-phase:nth-child(odd) .phase-content {
    margin-left: auto;
  }

  .roadmap-phase.active .phase-content,
  .phase-content:hover {
    opacity: 1;
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 245, 212, 0.2);
  }

  .phase-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .phase-number {
    font-size: 3rem;
    font-weight: bold;
    color: #00f5d4;
    margin-right: 15px;
    text-shadow: 0 0 10px rgba(0, 245, 212, 0.5);
  }

  .phase-content h2 {
    font-size: 2rem;
    color: #fff;
    margin: 0;
  }

  .phase-description {
    font-size: 1rem;
    color: #a0a0a0;
    margin-bottom: 20px;
  }

  .phase-items {
    list-style-type: none;
    padding: 0;
  }

  .phase-items li {
    margin-bottom: 10px;
    font-size: 0.9rem;
    color: #fff;
    opacity: 0.8;
    transition: all 0.3s ease;
    position: relative;
    padding-left: 20px;
  }

  .phase-items li::before {
    content: '▹';
    color: #00f5d4;
    position: absolute;
    left: 0;
  }

  .phase-items li:hover {
    color: #00f5d4;
    transform: translateX(5px);
  }

  .beetrade-phase {
    margin-top: 150px;
  }

  .beetrade-content {
    width: 100% !important;
    max-width: 1000px;
    margin: 0 auto !important;
    padding: 50px;
    background: rgba(26, 26, 26, 0.9);
    border-radius: 25px;
    backdrop-filter: blur(20px);
    border: 2px solid rgba(0, 245, 212, 0.3);
    box-shadow: 0 20px 50px rgba(0, 245, 212, 0.2);
  }

.beetrade-features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
    margin-top: 30px;
  }

  .feature {
    background: rgba(123, 44, 191, 0.1);
    padding: 20px;
    border-radius: 15px;
    transition: all 0.3s ease;
  }

  .feature:hover {
    background: rgba(123, 44, 191, 0.2);
    transform: translateY(-5px);
  }

  .feature h3 {
    color: #00f5d4;
    margin-bottom: 10px;
  }

  .feature p {
    font-size: 0.9rem;
    color: #fff;
    opacity: 0.8;
  }

  .platform-comparison {
    margin-top: 50px;
  }

  .platform-comparison h3 {
    text-align: center;
    color: #00f5d4;
    margin-bottom: 30px;
    font-size: 2rem;
  }

  .comparison-container {
    background: rgba(26, 26, 26, 0.5);
    border-radius: 15px;
    padding: 20px;
    transition: all 0.3s ease;
  }

  .comparison-container:hover {
    background: rgba(26, 26, 26, 0.7);
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 245, 212, 0.2);
  }

  .comparison-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
  }

  .comparison-header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    background: rgba(0, 245, 212, 0.1);
    padding: 15px;
    border-radius: 10px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .comparison-header div {
    text-align: center;
    color: #00f5d4;
  }

  .comparison-row {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    background: rgba(26, 26, 26, 0.3);
    padding: 15px;
    border-radius: 10px;
    transition: all 0.3s ease;
  }

  .comparison-row:hover {
    background: rgba(26, 26, 26, 0.5);
    transform: translateX(5px);
  }

  .comparison-row div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .comparison-row div:first-child {
    justify-content: flex-start;
    padding-left: 20px;
  }

  .checkmark {
    font-size: 1.2em;
  }

  .beetrade-checkmark {
    color: #00f5d4;
    animation: pulse 2s infinite;
  }

  .pumpfun-checkmark {
    color: #7b2cbf;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    .roadmap-header h1 {
      font-size: 3rem;
    }

    .roadmap-header p {
      font-size: 1.2rem;
    }

    .roadmap-timeline::before {
      left: 20px;
    }

    .phase-connector {
      left: 20px;
    }

    .phase-content {
      width: calc(100% - 50px);
      margin-left: 50px !important;
    }

    .connector-line {
      width: 30px;
      left: 0;
      transform: translateY(-50%);
    }

    .connector-dot {
      left: 0;
      transform: translateY(-50%);
    }

    .beetrade-content {
      padding: 30px;
    }

    .comparison-header,
    .comparison-row {
      grid-template-columns: 2fr 1fr 1fr;
    }

    .comparison-row div:first-child {
      padding-left: 10px;
    }
  }
`;

export default () => (
  <>
    <style>{styles}</style>
    <FuturisticRoadmapWithBeetrade />
  </>
);