import React, { useState, useEffect } from 'react';
import './App.css';
import { FaGlobe, FaTwitter, FaTelegram, FaLink } from 'react-icons/fa';
import { Connection, PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { Buffer } from 'buffer';
import Roadmap from './components/roadmap';
import NeonTicker from './components/NeonTicker';
import Chat from './components/Chat';
window.Buffer = Buffer;

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://beetop.cc';

function CountdownTimer({ launchDate }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const difference = new Date(launchDate) - new Date();
    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    return null;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [launchDate]);

  if (!timeLeft) return null;

  return (
    <div className="countdown">
      <span>{timeLeft.days}d </span>
      <span>{timeLeft.hours}h </span>
      <span>{timeLeft.minutes}m </span>
      <span>{timeLeft.seconds}s</span>
    </div>
  );
}

function TimePassedCounter({ launchDate }) {
  const [timePassed, setTimePassed] = useState(calculateTimePassed());

  function calculateTimePassed() {
    const now = new Date();
    const launch = new Date(launchDate);
    const difference = now - launch;
    
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);

    return `${days}d ${hours}h ${minutes}m`;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimePassed(calculateTimePassed());
    }, 60000);

    return () => clearInterval(timer);
  }, [launchDate]);

  return <span className="time-passed">{timePassed} ago</span>;
}

function TopProjects({ topProjects, onLike, onProjectClick }) {
  return (
    <div className="top-projects">
      <h2>Top 3 Projects</h2>
      <div className="top-projects-list">
        {topProjects.map((project, index) => (
          <div key={project.id} className={`top-project top-project-${index + 1}`} onClick={() => onProjectClick(project)}>
            {project.avatar_url && <img src={project.avatar_url} alt={project.name} className="project-avatar" />}
            <div className="project-info">
              <h2>{project.name} [{project.ticker}]</h2>
              <p>{project.description.length > 256 ? `${project.description.slice(0, 256)}...` : project.description}</p>
              <CountdownTimer launchDate={project.launch_date} />
              <div className="project-links">
                {project.website_url && <a href={project.website_url} target="_blank" rel="noopener noreferrer"><FaGlobe /></a>}
                {project.twitter_url && <a href={project.twitter_url} target="_blank" rel="noopener noreferrer"><FaTwitter /></a>}
                {project.telegram_url && <a href={project.telegram_url} target="_blank" rel="noopener noreferrer"><FaTelegram /></a>}
                {!project.website_url && <FaGlobe className="disabled-icon" />}
                {!project.twitter_url && <FaTwitter className="disabled-icon" />}
                {!project.telegram_url && <FaTelegram className="disabled-icon" />}
                <button onClick={(e) => {
                  e.stopPropagation();
                  copyRefLink(project.ref_code);
                }} className="copy-link-button">
                  <FaLink /> Copy Ref Link
                </button>
              </div>
            </div>
            <div className="likes">
              <span className="likes-count">{project.likes}</span>
              <button className="like-button" onClick={(e) => {
                e.stopPropagation();
                onLike(project.ref_code);
              }}>Like</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        {children}
      </div>
    </div>
  );
}

function ProjectModal({ project, onClose, onLike, walletAddress, connectWallet }) {
  if (!project) return null;

  return (
    <Modal isOpen={true} onClose={onClose}>
      <div className="project-modal">
        <h2>{project.name} [{project.ticker}]</h2>
        {project.avatar_url && <img src={project.avatar_url} alt={project.name} className="project-avatar" />}
        <p>{project.description}</p>
        <CountdownTimer launchDate={project.launch_date} />
        <div className="project-links">
          {project.website_url && <a href={project.website_url} target="_blank" rel="noopener noreferrer"><FaGlobe /></a>}
          {project.twitter_url && <a href={project.twitter_url} target="_blank" rel="noopener noreferrer"><FaTwitter /></a>}
          {project.telegram_url && <a href={project.telegram_url} target="_blank" rel="noopener noreferrer"><FaTelegram /></a>}
        </div>
        <div className="likes">
          <span className="likes-count">{project.likes}</span>
          {walletAddress ? (
            <button className="like-button" onClick={() => onLike(project.ref_code)}>Like</button>
          ) : (
            <button className="connect-wallet-button" onClick={connectWallet}>Connect Wallet to Like</button>
          )}
        </div>
      </div>
    </Modal>
  );
}

function AddProjectForm({ onSubmit, walletAddress }) {
  const [newProject, setNewProject] = useState({
    name: '',
    description: '',
    launch_date: '',
    avatar_url: '',
    website_url: '',
    twitter_url: '',
    telegram_url: '',
    ticker: ''
  });
  const [avatarFile, setAvatarFile] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name' && value.length > 60) return;
    if (name === 'ticker' && value.length > 16) return;
    if (name === 'description' && value.length > 500) return;
    setNewProject({ ...newProject, [name]: value });
  };

  const handleFileChange = (e) => {
    setAvatarFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (avatarFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onSubmit({ ...newProject, avatar_url: reader.result });
      };
      reader.readAsDataURL(avatarFile);
    } else {
      onSubmit(newProject);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        name="name"
        value={newProject.name}
        onChange={handleInputChange}
        placeholder="Project Name (max 60 characters)"
        required
        maxLength={60}
      />
      <input
        name="ticker"
        value={newProject.ticker}
        onChange={handleInputChange}
        placeholder="Ticker (max 16 characters)"
        required
        maxLength={16}
      />
      <textarea
        name="description"
        value={newProject.description}
        onChange={handleInputChange}
        placeholder="Description (max 500 characters)"
        maxLength={500}
      />
      <input
        type="datetime-local"
        name="launch_date"
        value={newProject.launch_date}
        onChange={handleInputChange}
        required
      />
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
      />
      <input
        name="website_url"
        value={newProject.website_url}
        onChange={handleInputChange}
        placeholder="Website URL"
      />
      <input
        name="twitter_url"
        value={newProject.twitter_url}
        onChange={handleInputChange}
        placeholder="Twitter URL"
      />
      <input
        name="telegram_url"
        value={newProject.telegram_url}
        onChange={handleInputChange}
        placeholder="Telegram URL"
      />
      <button type="submit" disabled={!walletAddress}>
        {walletAddress ? "Add Project (0.1 SOL)" : "Connect Wallet to Add Project"}
      </button>
    </form>
  );
}

function CompletedProjects({ completedProjects }) {
  return (
    <div className="completed-projects">
      <h2>Last started</h2>
      <div className="completed-projects-list">
        {completedProjects.slice(0, 5).map((project) => (
          <div key={project.id} className="completed-project">
            {project.avatar_url && <img src={project.avatar_url} alt={project.name} className="completed-project-avatar" />}
            <div className="completed-project-info">
              <h3>[{project.ticker}]</h3>
              <TimePassedCounter launchDate={project.launch_date} />
              <div className="completed-project-links">
                {project.website_url && <a href={project.website_url} target="_blank" rel="noopener noreferrer"><FaGlobe /></a>}
                {project.twitter_url && <a href={project.twitter_url} target="_blank" rel="noopener noreferrer"><FaTwitter /></a>}
                {project.telegram_url && <a href={project.telegram_url} target="_blank" rel="noopener noreferrer"><FaTelegram /></a>}
              </div>
              <div className="likes">
                <span className="likes-count">{project.likes}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function copyRefLink(refCode) {
  const link = `${window.location.origin}/project/${refCode}`;
  navigator.clipboard.writeText(link).then(() => {
    alert('Referral link copied to clipboard!');
  }).catch(err => {
    console.error('Failed to copy: ', err);
  });
}

function App() {
  const [projects, setProjects] = useState([]);
  const [completedProjects, setCompletedProjects] = useState([]);
  const [topProjects, setTopProjects] = useState([]);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showRoadmap, setShowRoadmap] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);

  const SOLANA_NETWORK = 'devnet';
  const PAYMENT_AMOUNT = 0.1 * LAMPORTS_PER_SOL;

  useEffect(() => {
    fetchProjects();
    fetchTopProjects();
    const interval = setInterval(() => {
      fetchProjects();
      fetchTopProjects();
    }, 5000);

    const storedToken = localStorage.getItem('authToken');
    const storedWalletAddress = localStorage.getItem('walletAddress');
    if (storedToken && storedWalletAddress) {
      setAuthToken(storedToken);
      setWalletAddress(storedWalletAddress);
    }

    const path = window.location.pathname;
    const match = path.match(/^\/project\/([a-zA-Z0-9]+)$/);
    if (match) {
      const refCode = match[1];
      fetchProjectByRefCode(refCode);
    }

    return () => clearInterval(interval);
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/projects`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      
      const now = new Date();
      const completed = data.filter(project => new Date(project.launch_date) <= now);
      const active = data.filter(project => new Date(project.launch_date) > now);
      
      const sortedActive = active.sort((a, b) => new Date(a.launch_date) - new Date(b.launch_date));
      const sortedCompleted = completed.sort((a, b) => new Date(b.launch_date) - new Date(a.launch_date));
      setProjects(sortedActive);
      setCompletedProjects(sortedCompleted);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError(`Failed to fetch projects: ${error.message}. Server: ${API_BASE_URL}`);
    }
  };

  const fetchTopProjects = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/top-projects`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const now = new Date();
      const activeTopProjects = data.filter(project => new Date(project.launch_date) > now);
      setTopProjects(activeTopProjects);
} catch (error) {
      console.error('Error fetching top projects:', error);
      setError(`Failed to fetch top projects: ${error.message}. Server: ${API_BASE_URL}`);
    }
  };

  const fetchProjectByRefCode = async (refCode) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/projects/${refCode}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const project = await response.json();
      setSelectedProject(project);
    } catch (error) {
      console.error('Error fetching project by ref code:', error);
      setError(`Failed to fetch project: ${error.message}. Server: ${API_BASE_URL}`);
    }
  };

  const connectWallet = async () => {
    if (typeof window.solana !== 'undefined') {
      try {
        const response = await window.solana.connect();
        console.log('Connected with Public Key:', response.publicKey.toString());

        const message = `Authenticate to Pump.fun: ${new Date().toISOString()}`;
        const encodedMessage = new TextEncoder().encode(message);
        const signedMessage = await window.solana.signMessage(encodedMessage, "utf8");

        if (signedMessage.signature) {
          const publicKey = response.publicKey.toString();
          setWalletAddress(publicKey);
          console.log('Authenticated with signature:', signedMessage.signature);

          const authResponse = await fetch(`${API_BASE_URL}/api/auth`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              publicKey,
              signature: Buffer.from(signedMessage.signature).toString('hex'),
              message,
            }),
          });

          if (authResponse.ok) {
            const { token } = await authResponse.json();
            setAuthToken(token);
            localStorage.setItem('authToken', token);
            localStorage.setItem('walletAddress', publicKey);
            console.log('Received auth token:', token);
          } else {
            throw new Error('Failed to authenticate with the server');
          }
        } else {
          throw new Error('Failed to sign the message');
        }
      } catch (error) {
        console.error('Error connecting to Phantom wallet:', error);
        setError(`Failed to connect and authenticate with Phantom wallet: ${error.message}`);
        setWalletAddress(null);
        setAuthToken(null);
        localStorage.removeItem('authToken');
        localStorage.removeItem('walletAddress');
      }
    } else {
      setError('Phantom wallet is not installed. Please install it from https://phantom.app/');
    }
  };

  const disconnectWallet = () => {
    if (window.solana && window.solana.isConnected) {
      window.solana.disconnect();
    }
    setWalletAddress(null);
    setAuthToken(null);
    localStorage.removeItem('authToken');
    localStorage.removeItem('walletAddress');
    console.log('Disconnected from Phantom wallet');
  };

  const handleAddProject = async (projectData) => {
    if (!walletAddress || !authToken) {
      setError('Please connect your wallet first.');
      return;
    }

    try {
      const connection = new Connection(
        SOLANA_NETWORK === 'devnet' 
          ? 'https://api.devnet.solana.com' 
          : 'https://api.mainnet-beta.solana.com',
        'confirmed'
      );

      const balance = await connection.getBalance(new PublicKey(walletAddress));
      console.log('Account balance:', balance / LAMPORTS_PER_SOL, 'SOL');

      if (balance < PAYMENT_AMOUNT + 10000000) {
        throw new Error(`Insufficient balance. You need at least ${(PAYMENT_AMOUNT + 10000000) / LAMPORTS_PER_SOL} SOL`);
      }

      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: new PublicKey(walletAddress),
          toPubkey: new PublicKey('463g8oU9g2DgtnHUvZmhiGJpSCad8657BihrgmndfMXG'),
          lamports: PAYMENT_AMOUNT,
        })
      );

      const { blockhash } = await connection.getRecentBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = new PublicKey(walletAddress);

      const signedTransaction = await window.solana.signTransaction(transaction);

      const signature = await connection.sendRawTransaction(signedTransaction.serialize());

      await connection.confirmTransaction(signature);

      console.log('Transaction confirmed:', signature);

      const response = await fetch(`${API_BASE_URL}/api/projects`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ ...projectData, transactionSignature: signature }),
      });

      if (!response.ok) {
        throw new Error('Failed to add project');
      }

      const result = await response.json();
      console.log('Project added successfully:', result);
      showModal('Project added successfully!');

      fetchProjects();
      fetchTopProjects();
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error adding project:', error);
      setError(`Failed to add project: ${error.message}`);
    }
  };

  const showModal = (message) => {
    setModalMessage(message);
    setTimeout(() => {
      setModalMessage(null);
    }, 3000);
  };

  const handleLike = async (refCode) => {
    if (!walletAddress || !authToken) {
      showModal('Please connect your wallet to like projects.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/projects/${refCode}/like`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.error === "You've already liked this project") {
          showModal('You have already liked this project');
        } else {
          throw new Error(errorData.error || 'Failed to like project');
        }
      } else {
        showModal('Project liked successfully!');
      }
      
      fetchProjects();
      fetchTopProjects();
      if (selectedProject && selectedProject.ref_code === refCode) {
        fetchProjectByRefCode(refCode);
      }
    } catch (error) {
      console.error('Error liking project:', error);
      showModal(error.message);
    }
  };

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  return (
    <div className="App">
      <header>
        <div className="header-content">
          <div className="header-left">
            <h1 onClick={() => {
              setShowRoadmap(false);
              window.location.href = '/';
            }} style={{ cursor: 'pointer' }}>BEETOP</h1>
            <div className="neon-ticker-header">
              <NeonTicker />
            </div>
          </div>
          <nav>
            <ul>
              <li><a href="#" onClick={() => setIsModalOpen(true)}>Add New Project</a></li>
              <li><a href="#">How to use?</a></li>
              <li><a href="#" onClick={() => setShowRoadmap(true)}>Roadmap</a></li>
              <li className="wallet-info-container">
                {walletAddress && (
                  <span className="wallet-status">
                    Connected: {walletAddress.slice(0, 4)}...{walletAddress.slice(-4)}
                  </span>
                )}
                {walletAddress ? (
                  <button onClick={disconnectWallet}>Disconnect Wallet</button>
                ) : (
                  <button onClick={connectWallet}>Connect Wallet</button>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <main>
        {error && <div className="error-message">{error}</div>}
        {modalMessage && <div className="modal-message">{modalMessage}</div>}
        {showRoadmap ? (
          <Roadmap />
        ) : (
          <div className="content-wrapper">
            <div className="main-content">
              <div className="left-column">
                <CompletedProjects completedProjects={completedProjects} />
              </div>
              <div className="center-column">
                <TopProjects 
                  topProjects={topProjects.slice(0, 3)} 
                  onLike={handleLike} 
                  onProjectClick={handleProjectClick}
                />
                <section className="project-list">
                  <h2>Upcoming Projects</h2>
                  <div className="projects">
                    {projects.map((project) => (
                      <div key={project.id} className="project" onClick={() => handleProjectClick(project)}>
                        {project.avatar_url && <img src={project.avatar_url} alt={project.name} className="project-avatar" />}
                        <div className="project-info">
                          <h2>{project.name} [{project.ticker}]</h2>
                          <p>{project.description.length > 256 ? project.description.slice(0, 256) + '...' : project.description}</p>
                          <CountdownTimer launchDate={project.launch_date} />
                          <div className="project-links">
                            {project.website_url && <a href={project.website_url} target="_blank" rel="noopener noreferrer"><FaGlobe /></a>}
                            {project.twitter_url && <a href={project.twitter_url} target="_blank" rel="noopener noreferrer"><FaTwitter /></a>}
                            {project.telegram_url && <a href={project.telegram_url} target="_blank" rel="noopener noreferrer"><FaTelegram /></a>}
                            {!project.website_url && <FaGlobe className="disabled-icon" />}
                            {!project.twitter_url && <FaTwitter className="disabled-icon" />}
                            {!project.telegram_url && <FaTelegram className="disabled-icon" />}
                            <button onClick={(e) => {
                              e.stopPropagation();
                              copyRefLink(project.ref_code);
                            }} className="copy-link-button">
                              <FaLink /> Copy Ref Link
                            </button>
                          </div>
                        </div>
                        <div className="likes">
                          <span className="likes-count">{project.likes}</span>
                          <button className="like-button" onClick={(e) => {
                            e.stopPropagation();
                            handleLike(project.ref_code);
                          }}>Like</button>
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              </div>
              <div className="right-column">
                {walletAddress && <Chat walletAddress={walletAddress} />}
              </div>
            </div>
          </div>
        )}
      </main>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>Add New Project</h2>
        <AddProjectForm onSubmit={handleAddProject} walletAddress={walletAddress} />
      </Modal>
      {selectedProject && (
        <ProjectModal
          project={selectedProject}
          onClose={() => setSelectedProject(null)}
          onLike={handleLike}
          walletAddress={walletAddress}
          connectWallet={connectWallet}
        />
      )}
    </div>
  );
}

export default App;